import { Outlet } from "react-router-dom";
import  Navbar  from "../components/Navbar";
import Footer from "../components/Footer";
import PopupWidget from "../components/PopupWidget";

const Layout = () => {
    return (
        <>
            <Navbar />
            <Outlet />
            <Footer />
            <PopupWidget />
        </>
    )
};

export default Layout;