import ContactForm from "../components/ContactForm";

const Contact = () => {
    return (
        <>
         <ContactForm />
        </>
    )
}

export default Contact;