import {
  FaceSmileIcon,
  ChartBarSquareIcon,
  CursorArrowRaysIcon,
  DevicePhoneMobileIcon,
  AdjustmentsHorizontalIcon,
  SunIcon,
} from "@heroicons/react/24/solid";

import benefitOneImg from "../assets/img/image1.jpg";
import benefitTwoImg from "../assets/img/image2.jpg";
import benefitThreeImg from "../assets/img/image4.jpg";
import benefitFoutImg from "../assets/img/image5.jpg";

const benefitOne = {
  title: "Woodworking",
  desc: "From initial construction to renovations and maintenance projects, our team of expert carpenters is here to provide you with customized solutions and exceptional results that will transform your home into a beautiful and functional space.",
  image: benefitOneImg,
  bullets: [
    {
      title: "Customized Construction",
      desc: "Our highly skilled carpenters build custom wood structures with meticulous attention to detail.",
      icon: <FaceSmileIcon />,
    },
    {
      title: "Interior Installation",
      desc: "From elegant doors and windows to intricate moldings and decorative panels, our interior woodwork installation expertise adds a touch of elegance and warmth to every room in your home.",
      icon: <FaceSmileIcon />,
    },
    {
      title: "Customized Furniture",
      desc: "Looking for unique furniture that perfectly fits your style and space? Our woodworking experts create custom furniture that reflects your personality and your specific needs.",
      icon: <FaceSmileIcon />,
    },
  ],
};

const benefitTwo = {
  title: "Bricklaying",
  desc: "From initial construction to renovations and repairs, our team of expert masons is here to provide you with solid, long-lasting building solutions that will transform your home into a place of beauty and comfort.",
  image: benefitTwoImg,
  bullets: [
    {
      title: "Structural Construction",
      desc: "From foundations to walls and roofs, our expertise in building structures provides a solid foundation for your home.",
      icon: <FaceSmileIcon />,
    },
    {
      title: "Installation of coatings",
      desc: "Beautify your home with superior siding.",
      icon: <FaceSmileIcon />,
    },
  ],
};

const benefitThree = {
  title: "Plumbing",
  desc: "Dedicated to providing reliable, efficient, and professional plumbing services to our valued customers.",
  image: benefitThreeImg,
  bullets: [
    {
      title: "Emergency Plumbing Repairs",
      desc: "Plumbing emergencies can happen at any time, day or night.",
      icon: <FaceSmileIcon />,
    },
    {
      title: "Drain Cleaning and Unclogging",
      desc: "A clogged drain can bring your household to a standstill.",
      icon: <FaceSmileIcon />,
    },
    {
      title: "Water Heater Services",
      desc: "Whether you need a new water heater installed or your existing unit repaired, our technicians have the expertise to keep your hot water flowing reliably.",
      icon: <FaceSmileIcon />,
    },
  ],
};

const benefitFour = {
  title: "Painting",
  desc: "Transforming your space with a fresh coat of paint can breathe new life into your home or business. ",
  image: benefitFoutImg,
  bullets: [
    {
      title: "Interior Painting",
      desc: "Whether you're looking to refresh a single room or repaint your entire home, our interior painting services can bring your vision to life.",
      icon: <FaceSmileIcon />,
    },
    {
      title: "Exterior Painting",
      desc: "Boost your curb appeal and protect your property from the elements with our exterior painting services.",
      icon: <FaceSmileIcon />,
    },
  ],
};


export {benefitOne, benefitTwo, benefitThree, benefitFour};
