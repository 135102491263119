import Benefits from "../components/Benefits";
import Hero from "../components/Hero";
import SectionTitle from "../components/SectionTitle";
import { benefitOne, benefitTwo, benefitThree, benefitFour } from "../components/data";

const Home = () => {
    return (
        <>
            <Hero />
            <SectionTitle
                title="Our Services"
            >
            </SectionTitle>
            <Benefits data={benefitOne} />
            <Benefits imgPos="right" data={benefitTwo} />
            <Benefits data={benefitThree} />
            <Benefits imgPos="right" data={benefitFour} />
        </>
    )
}

export default Home;